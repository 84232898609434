import(/* webpackMode: "eager" */ "/var/www/vhosts/wazifame.com/httpdocs/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/wazifame.com/httpdocs/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/wazifame.com/httpdocs/src/app/(www)/(auth)/register/_components/RegisterImagesSlider.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/wazifame.com/httpdocs/src/assets/images/lines.png");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/wazifame.com/httpdocs/src/assets/images/logo.svg");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/wazifame.com/httpdocs/src/assets/images/SignupImage.webp");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/wazifame.com/httpdocs/src/assets/images/SignupImage2.webp");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/wazifame.com/httpdocs/src/assets/images/SignupImage3.webp");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/wazifame.com/httpdocs/src/assets/images/SignupImage4.webp");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/vhosts/wazifame.com/httpdocs/src/components/Login/LoginForm.tsx");
